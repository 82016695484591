import React from "react";
import { mdiMinus, mdiPlus, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import backToProductsUrl from "assets/mobile/ProductsPage/backToProductsDark.svg";
import styles from "styles/mobile/Dashboard/User/Shop/Cart.module.css";
import Button from "components/Button";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { toast } from "react-toastify";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import locale from "date-fns/locale/pl";
import { DateCalendar, MobileTimePicker, plPL } from "@mui/x-date-pickers";
import { useAuthContext } from "hooks/useAuthContext";

import AvailabilityChecker from "./checkAvailability";
import formatDateTime from "helpers/formatDateTime";
import DeliveryDates from "helpers/DeliveryDates";

const CartPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { orderDate } = useAuthContext();

	const params = useParams();
	const [productList, setProductList] = useState([]);
	const [amountProducts, setAmountProducts] = useState({});
	const [noteProducts, setNoteProducts] = useState({});
	const [summary, setSummary] = useState("0");
	const [deliveryTime, setDeliveryTime] = useState(false);
	const [deliveryTimeRange, setDeliveryTimeRange] = useState();
	const [device, setDevice] = useState({});
	const { usePost: usePostSendOrder } = useFetch(PathsAPI.UserOrders);
	const { request: reqSendOrder, loading: loadingSendOrder } = usePostSendOrder();
	const [openTimePicker, setOpenTimePicker] = useState(false);
	const timePickerRef = useRef();

	const [shopIsClosed, setShopIsClosed] = useState(false);
	const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
	const [datePickerDateRange, setDatePickerDateRange] = useState({ min: new Date(), max: new Date() });
	const { useGet } = useFetch(`${PathsAPI.Suppliers}?device=${params.id}`);
	const { response: shopsInfo } = useGet(true);

	const availabilityChecker = new AvailabilityChecker(orderDate, shopsInfo);

	const { useGet: useDeviceGet } = useFetch(`${PathsAPI.Devices}/user`);
	const { response: deviceList } = useDeviceGet(true);
	const [blockCart, setBlockCart] = useState(false);

	const { usePut: usePutSendVoucherOrder } = useFetch(PathsAPI.VoucherUser);
	const { request: reqSendVoucherOrder, loading: loadingSendVoucherOrder } = usePutSendVoucherOrder();

	const { usePut: usePutSendDiscountOrder } = useFetch(PathsAPI.DiscountOrder);
	const { request: reqSendDiscountOrder, loading: loadingSendDiscountOrder } = usePutSendDiscountOrder();

	const { usePut: useOrderCartPut, useGet: useOrderCartGet } = useFetch(PathsAPI.OrderUserCart(params.id, "cart"));
	const { request: updateCart } = useOrderCartPut();
	const { response: savedCart } = useOrderCartGet(true);

	const [minCartCostList, setMinCartCostList] = useState({});

	const [viewDiscountCode, setViewDiscountCode] = useState(false);
	const discountCodeRef = useRef();
	const { useGet: useGetDiscountCodes, usePost: usePostDiscountCode, useDelete } = useFetch(PathsAPI.OrderUserDiscountCode);
	const { response: discountCode, request: refreshDiscountCode } = useGetDiscountCodes(true);
	const { request: activateDiscountCode } = usePostDiscountCode();
	const { request: deactivateDiscountCode } = useDelete();
	const [discountWaring, setDiscountWarning] = useState(false);

	useEffect(() => {
		if (!savedCart || !shopsInfo) {
			return;
		}

		const { amount, products, summary: newSummary, minCartCost } = savedCart;

		for (const [supplier, value] of Object.entries(minCartCost)) {
			if (typeof value === "number") {
				const shopData = shopsInfo.find((shop) => shop._id === supplier);
				toast.warn(
					<>
						Brakuje <b>{value.toFixed(2)} PLN</b> do minimalnego koszyka od dostawcy <b>{shopData.name}</b>
					</>
				);
			}
		}

		setProductList(products);
		setAmountProducts(amount);
		setSummary(newSummary);
		setMinCartCostList(minCartCost);
	}, [shopsInfo, savedCart]);

	useEffect(() => {
		if (!deviceList) {
			return;
		}

		const { _id, locatedAt } = deviceList.find((d) => d._id === params.id);

		setDevice({ id: _id, location: locatedAt });
	}, [deviceList, setDevice, params.id]);

	useEffect(() => {
		if (!shopsInfo) {
			return;
		}

		if (!shopsInfo) {
			return;
		}

		if (!(productList.length > 0)) {
			return;
		}

		const deliveryDates = new DeliveryDates(shopsInfo, productList);

		setDeliveryTimeRange({ timeMin: deliveryDates.startShop, timeMax: deliveryDates.closeShop });

		const startDeliveryTime = deliveryDates.getStartDeliveryTime(orderDate);

		if (startDeliveryTime) {
			!deliveryTime && setDeliveryTime(startDeliveryTime);
		} else {
			setShopIsClosed(true);
			setDatePickerDateRange(deliveryDates.potentialAvailableDays());
		}
	}, [shopsInfo, productList, params.id, orderDate, shopIsClosed, deliveryTime]);

	const changeCartItemQuantity = async (productId, newAmount) => {
		const { amount, products, summary: newSummary, minCartCost } = await updateCart({ itemId: productId, amount: newAmount, mode: "all" });

		setProductList(products);
		setAmountProducts(amount);
		setSummary(newSummary);
		setMinCartCostList(minCartCost);

		for (const [supplier, value] of Object.entries(minCartCost)) {
			if (typeof value === "number") {
				const shopData = shopsInfo.find((shop) => shop._id === supplier);
				toast.warn(
					<>
						Brakuje <b>{value.toFixed(2)} PLN</b> do minimalnego koszyka od dostawcy <b>{shopData.name}</b>
					</>
				);
			}
		}
	};

	useEffect(() => {
		if (!amountProducts) {
			return;
		}

		if (!blockCart && Object.values(amountProducts).includes("voucher")) {
			setBlockCart(true);
		}
	}, [amountProducts, blockCart]);

	const voucherPayment = async () => {
		try {
			const note = Object.values(noteProducts)[0] || "";

			const { status, returnToken } = await reqSendVoucherOrder({ device, deliveryTime, note });
			if (status === "ok") {
				navigate(`/dashboard/shop/online/pay/success/${returnToken}`);
			} else {
				navigate(`/dashboard/shop/online/pay/failure/${returnToken}`);
			}
		} catch (error) {
			toast.error(error);
		}
	};

	const discountPayment = async () => {
		try {
			const { status, returnToken } = await reqSendDiscountOrder({ device, deliveryTime, notes: noteProducts, supplier: params.id });
			if (status === "ok") {
				navigate(`/dashboard/shop/online/pay/success/${returnToken}`);
			} else {
				navigate(`/dashboard/shop/online/pay/failure/${returnToken}`);
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	const defaultPayment = async () => {
		try {
			const { paymentUrl } = await reqSendOrder({
				device,
				deliveryTime,
				notes: noteProducts,
				supplier: params.id,
			});
			if (paymentUrl) {
				window.location.href = paymentUrl;
			} else {
				toast.error("Wystąpił problem z złożeniem zamówienia");
			}
		} catch (error) {
			toast.error(error);
		}
	};

	const handleSendOrder = async () => {
		if (blockCart) {
			voucherPayment();
		} else if (discountCode) {
			discountPayment();
		} else {
			defaultPayment();
		}
	};

	const handleChangeDeliveryTime = (value) => {
		if (value.getHours() === deliveryTimeRange.timeMax.getHours()) {
			value.setMinutes(0);
		}
		setDeliveryTime(value);
	};

	const handleBack = () => {
		const newPathname = location.pathname.replace("cart", "");
		navigate(newPathname, { state: location.state });
	};

	const returnNameColor = (product) => {
		if (!shopsInfo) {
			return "";
		}

		if (availabilityChecker.checkAvailability(product) !== true) {
			return "var(--error-color)";
		}

		if (typeof minCartCostList[product.supplier] === "number") {
			return "orange";
		}

		return "";
	};

	const onNoteChange = (e, productId) => {
		setNoteProducts((n) => ({ ...n, [productId]: e.target.value }));
	};

	const handleChangeDate = () => {
		setOpenDateTimePicker(!openDateTimePicker);
	};
	const handleChangeDeliveryDate = (value) => {
		setDeliveryTime(value);
		setOpenDateTimePicker(false);
		setShopIsClosed(false);
	};

	const handleChangeDiscountCode = () => {
		setViewDiscountCode(!viewDiscountCode);
	};

	const handleAddDiscountCode = async () => {
		const code = discountCodeRef.current.value;
		try {
			await activateDiscountCode({ code });
			await refreshDiscountCode();
			toast.success("Kod rabatowy został dodany");
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleDeactivateDiscountCode = async () => {
		await deactivateDiscountCode();
		await refreshDiscountCode();
		toast.success("Kod rabatowy został anulowany");
	};

	useEffect(() => {
		if (!discountCode) {
			return;
		}

		if (discountCode.currentValue < summary) {
			toast.error("Kod rabatowy nie pokrywa całej kwoty zamówienia, aby kontynuować usuń kod rabatowy lub zmniejsz wartość zamówienia");
			setDiscountWarning(true);
		} else {
			setDiscountWarning(false);
		}
	}, [discountCode, summary]);

	return (
		<div className={styles.container}>
			<img style={{ height: "3rem", margin: "auto", marginLeft: "32px", marginTop: "32px" }} src={backToProductsUrl} alt="cofnij" onClick={handleBack} />
			<h1>Koszyk</h1>
			<div
				className={styles.overlay}
				onClick={() => {
					setOpenDateTimePicker(false);
				}}
				style={{ display: openDateTimePicker ? "block" : "none" }}
			></div>
			<div className={styles.cartContainer}>
				{productList.length > 0 ? (
					productList.map((product, index) => (
						<div key={product._id} className={styles.productContainer}>
							<div className={styles.productInfoContainer}>
								<img
									loading="lazy"
									alt={product.name}
									src={product.photoUrl}
									onError={(e) => {
										e.target.src = "/404.png";
									}}
								></img>
								<div className={styles.productInfo}>
									<div className={styles.productTitle}>
										<b
											style={{
												fontSize: "1.1em",
												color: returnNameColor(product),
											}}
										>
											{product.name.slice(0, 32)}
											{product.name.length > 32 ? ".." : ""}
										</b>
									</div>
									<b style={{ fontSize: "1.2em" }}>{((typeof amountProducts[product._id] === "number" ? amountProducts[product._id] : 1) * product.brutto).toFixed(2)} zł</b>
								</div>
								<div className={styles.productAddToCartContainer}>
									<div className={styles.productCartControls}>
										<Icon
											style={{
												height: "1rem",
												width: "1rem",
												cursor: "pointer",
												background: product.blockAmount ? "#3c3c3b" : "var(--primary)",
												color: "#fff",
												borderRadius: "8px",
												padding: "4px",
											}}
											path={mdiMinus}
											onClick={() => {
												if (product.blockAmount) {
													return;
												}

												amountProducts[product._id] > 1 && changeCartItemQuantity(product._id, amountProducts[product._id] - 1);
											}}
										/>
										<div style={{ textAlign: "center", padding: "0 0.5em" }}>{amountProducts[product._id]}</div>
										<Icon
											style={{
												height: "1rem",
												width: "1rem",
												cursor: "pointer",
												background: product.blockAmount ? "#3c3c3b" : "var(--primary)",
												color: "#fff",
												borderRadius: "8px",
												padding: "4px",
											}}
											path={mdiPlus}
											onClick={() => {
												if (product.blockAmount) {
													return;
												}

												changeCartItemQuantity(product._id, amountProducts[product._id] + 1);
											}}
										/>
									</div>
								</div>
							</div>
							<div className={styles.productCartRemove}>
								<Icon
									style={{
										height: "2rem",
										width: "2rem",
										cursor: "pointer",
										background: "var(--primary)",
										color: "#fff",
										borderRadius: "8px",
										padding: "4px",
									}}
									path={mdiTrashCan}
									onClick={() => {
										changeCartItemQuantity(product._id, 0);
									}}
								/>
							</div>
							<div>
								<input type="text" placeholder="Uwagi do zamówienia" value={product.note} onChange={(e) => onNoteChange(e, index)} />
							</div>
						</div>
					))
				) : (
					<div className={styles.emptyCartMessage}>Twój koszyk jest pusty</div>
				)}
			</div>
			<div className={styles.deliveryTime}>
				{blockCart && (
					<h3 style={{ color: "red", textAlign: "center" }}>
						Uwaga
						<br />
						Cofnięcie usunie dodany voucher z koszyka!
					</h3>
				)}
				<div style={{ fontSize: "1.3em", fontWeight: 600, marginBottom: 4 }}>Lokalizacja urządzenia</div>
				<div>{device?.location}</div>
				<div style={{ fontSize: "1.3em", fontWeight: 600, marginTop: 32, marginBottom: 8 }}>Wybierz godzinę dostawy</div>

				{!deliveryTime ? (
					<Button>Ładowanie ..</Button>
				) : (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}>
						{shopIsClosed === false ? (
							<>
								<Button
									onClick={() => {
										setOpenTimePicker(!openTimePicker);
									}}
									disabled={productList.length === 0}
									ref={timePickerRef}
								>
									{productList.length > 0
										? deliveryTime
											? new Date(deliveryTime).toLocaleString("pl", {
													day: "2-digit",
													month: "2-digit",
													hour: "2-digit",
													minute: "2-digit",
											  })
											: "Ładowanie .."
										: "Najpierw dodaj produkty do koszyka"}
								</Button>
								<MobileTimePicker
									toolbarTitle="Godzina dostawy"
									open={openTimePicker}
									onClose={() => {
										setOpenTimePicker(false);
									}}
									slotProps={{ popper: { anchorEl: timePickerRef.current } }}
									slots={{ field: () => <></> }}
									value={deliveryTime}
									onChange={handleChangeDeliveryTime}
									disablePast={deliveryTime.toDateString() === new Date().toDateString() ? true : false}
									minTime={deliveryTimeRange?.timeMin}
									maxTime={deliveryTimeRange?.timeMax}
								/>
							</>
						) : (
							<div className={styles.shopClosedContainer}>
								<p>Sklep jest zamknięty dnia {formatDateTime(deliveryTime, "date")}</p>
								<Button className={styles.deliveryTimeButton} onClick={handleChangeDate}>
									Wybierz inny dzień
								</Button>
								<DateCalendar
									className={`${openDateTimePicker ? styles.dateCalendarOpen : styles.dateCalendarClosed} ${styles.dateCalendar}`}
									value={deliveryTime}
									onChange={handleChangeDeliveryDate}
									disablePast={true}
									minDate={datePickerDateRange.min}
									maxDate={datePickerDateRange.max}
									views={["day"]}
								/>
							</div>
						)}
					</LocalizationProvider>
				)}
			</div>
			<div className={styles.bottomContainer}>
				{!blockCart && (
					<>
						{discountCode ? (
							<div style={{ background: "var(--primary)", padding: "1em", borderRadius: "1em", gap: 8, display: "flex", flexDirection: "row" }}>
								<p>
									<b>{discountCode.code}</b> | Pozostała wartość: {discountCode.currentValue.toFixed(2)} zł
								</p>
								<Button onClick={handleDeactivateDiscountCode}>Anuluj</Button>
							</div>
						) : viewDiscountCode ? (
							<div style={{ background: "var(--primary)", padding: "1em", borderRadius: "1em", gap: 8, display: "flex", flexDirection: "row" }}>
								<input type="text" placeholder="Podaj kod rabatowy" ref={discountCodeRef} />
								<Button onClick={handleAddDiscountCode}>Zatwierdź</Button>
							</div>
						) : (
							<Button onClick={handleChangeDiscountCode}>Użyj kodu rabatowego</Button>
						)}
					</>
				)}

				<p style={discountWaring ? { color: "var(--error-color)" } : {}}>Suma: {summary}zł</p>
				<Button disabled={!(productList.length > 0) || loadingSendOrder || loadingSendVoucherOrder || loadingSendDiscountOrder} onClick={handleSendOrder}>
					Zamawiam
				</Button>
			</div>
		</div>
	);
};

export default CartPage;

const getOrderStatus = (order) => {
	if (order === null) {
		return { message: "Nieznany" };
	}

	const { statusHistory } = order;

	const status = statusHistory[statusHistory.length - 1]?.status;

	switch (status) {
		case "PAYMENT_ERROR":
			return { message: "Płatność nieudana" };
		case "WAIT_FOR_PAYMENT":
			return { message: "Oczekuje na płatność" };
		case "WAIT_FOR_ACCEPT":
			return { message: "Opłacono, oczekuje na zatwierdzenie" };
		case "ACCEPTED_TO_DELIVER":
			return { message: "Przyjęte do dostarczenia" };
		case "DECLINED":
			return { message: "Odrzucone", declined: true };
		case "DELIVERED":
			return { message: "Dostarczone", receive: true };
		case "RECEIVED_PART":
			return { message: "Odebrano część zamówienia" };
		case "RECEIVED":
			return { message: "Odebrane", received: true };
		case "PAYMENT_REFUND":
			return { message: "Płatność zwrócona", refund: true };
		case "USED_VOUCHER":
			return { message: "Opłacono voucherem, oczekuje na zatwierdzenie" };
		case "USED_DISCOUNT_CODE":
			return { message: "Opłacono kodem rabatowym, oczekuje na zatwierdzenie" };
		case "IN_RETURN":
			return { message: "W trakcie zwrotu", return: true };
		case "RETURNED":
			return { message: "Zwrócone", return: true, returned: true };
		default:
			return { message: "Nieznany" };
	}
};

export default getOrderStatus;
